<template>
	<div>
		<transition name="fade">
			<Policy/>
		</transition>
	</div>
</template>

<script>
import Policy from "@/components/Policy";
import { mapGetters } from 'vuex'

export default {
	name: 'HomeChoice',
	components: {
		Policy,
	},
	created() {
		this.$store.dispatch('fetchCategorie')
	},
	computed: {
		...mapGetters({
			tipologiaPolizza: 'getTipologiaPolizza'
		})
	}
};
</script>


<style scoped>
.hide {
	display: none;
}
</style>

