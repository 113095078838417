<template>
	<v-app>
		<v-row no-gutters align-content="space-between">
			<Header></Header>
			<v-col cols="12">
				<v-main>
					<router-view/>
				</v-main>
			</v-col>
			<Footer></Footer>
		</v-row>
	</v-app>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default {
	name: "App",
	components: {
		Header,
		Footer
	},

	data: () => ({
		//
	})
};
</script>
