<template>
	<v-row no-gutters class="accent pa-10 header">
		<v-col cols="12" md="8" align-self="center">
			<v-row no-gutters justify-start align-center>
				<v-col cols="12" class="new-section-title text-left">
					<div @click="reset" style="cursor: pointer;">
						<img src="@/assets/images/Raggruppa-928.svg" height="100px">
					</div>
				</v-col>
				<v-col cols="12" class="new-banner-content">
					MFB Underwriting Agency è una società di intermediazione assicurativa che opera nel campo della responsabilità sanitaria
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="4">
			<v-img src="@/assets/images/Raggruppa-867.svg" height="200px" contain></v-img>
		</v-col>
	</v-row>
</template>


<script>
export default {
	methods: {
		reset() {
			this.$store.dispatch('resetAll')
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
};
</script>

<style scoped>
.underline {
	text-decoration: underline;
}

.header{
	width: 100%;
}

.new-banner-content {
	font-family: "Nunito", sans-serif;
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 1.75rem;
	letter-spacing: 0.05rem;
	color: #204177;
	opacity: 1;
}

.new-section-title {
	font-family: "Roboto", sans-serif;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.05rem;
	color: #204177;
	opacity: 1;
}
</style>
