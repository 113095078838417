import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#204177',
				secondary: '#F5CF58',
				accent: '#fde9ac'
			},
		},
	},
});
