import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/result/:id",
		name: "result",
		component: () => import("@/views/Result")
	},
	{
		path: "*",
		name: "page-not-found",
		component: () => import("@/views/PageNotFound.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
