<template>
	<v-row no-gutters justify="center">
		<v-col cols="12" lg="7">
			<v-row class="text-center pa-10 mt-5">
				<v-col cols="12">
					<span class="section-title">{{ categoria.nome }}</span>
				</v-col>
				<v-col cols="12">
				<span class="section-sub-title">
					{{ categoria.descrizione }}
				</span>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12"></v-col>
		<v-col cols="4" lg="3" class="selected text-center secondary mb-3 pa-5">
			{{ tipologiaPolizza.nome }}
		</v-col>
		<v-col cols="12" class="section-title text-center pb-10 pt-10">
			<v-row no-gutters justify="center">
				<v-col cols="10">SELEZIONA LA CONDIZIONE PROFESSIONALE</v-col>
				<v-col cols="12" md="9">
					<v-row no-gutters>
							<v-col class="pa-5" cols="12" md="4" v-for="item in categorieProfessionali" :key="item.id">
								<v-row no-gutters class="card pa-5" justify="center" :class="{ 'active': categoriaProfessionale?.id === item.id }">
									<v-col cols="12 pb-4" class="text-center">
										{{item.nome}}
									</v-col>
									<v-col cols="12" class="text-center">
										<v-btn
											depressed
											color="#EFFAF6"
											@click="selectCategoria(item)">
											<span style="color: #4CAA8B; letter-spacing: 0.05rem">
												SELEZIONA CATEGORIA
											</span>
											<v-icon color="#4CAA8B">mdi-chevron-right</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" id="categorie_rischio" class="section-title text-center pb-10" v-if="categorieRischio">
			<v-row no-gutters justify="center">
				<v-col cols="10">SELEZIONA LA CATEGORIA DI RISCHIO</v-col>
				<v-col cols="12" md="9">
					<v-row no-gutters>
							<v-col class="pa-5" cols="12" md="4" v-for="item in categorieRischio" :key="item.id">
								<v-row no-gutters class="card pa-5 fill-height" justify="center" align-content="space-around" :class="{ 'active': categoriaRischio?.id === item.id }">
									<v-col cols="12" class="text-center pb-4">
										{{item.nome}}
									</v-col>
									<v-col cols="12" class="text-center">
										<v-btn
											depressed
											color="#EFFAF6"
											@click="selectCategoriaRischio(item)">
											<span style="color: #4CAA8B; letter-spacing: 0.05rem">
												SELEZIONA CATEGORIA
											</span>
											<v-icon color="#4CAA8B">mdi-chevron-right</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" id="elenco_polizze" class="section-title text-center pb-10" v-if="polizze">
			<v-row no-gutters justify="center">
				<v-col cols="10">SOTTO LIMITE PER SINISTRO E PER ANNO</v-col>
				<v-col cols="12" md="9">
					<v-row no-gutters>
							<v-col class="pa-5" cols="12" md="3" v-for="item in polizze" :key="item.id">
								<v-row no-gutters class="card pa-5 fill-height" justify="center" align-content="space-around" :class="{ 'active': polizza?.id === item.id }">
									<v-col cols="12" class="text-center pb-1">
										MASSIMALE
									</v-col><v-col cols="12" class="text-center pb-4">
										{{item.massimale}} €
									</v-col>
									<v-col cols="12" class="text-center pb-1">
										PREMIO
									</v-col>
									<v-col cols="12" class="text-center pb-4">
										{{item.premio}} €
									</v-col>
									<v-col cols="12" class="text-center">
										<v-btn
											depressed
											color="#EFFAF6"
											@click="selectPolizza(item)">
											<span style="color: #4CAA8B; letter-spacing: 0.05rem">
												ACQUISTA
											</span>
											<v-icon color="#4CAA8B">mdi-chevron-right</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" id="form_polizza" class="text-center pb-10" v-if="polizza">
			<v-row no-gutters justify="center">
				<v-col cols="12" class="section-title">DATI ANAGRAFICI</v-col>
				<v-col cols="9">
					<v-form ref="form" lazy-validation>
						<v-row no-gutters align="start" justify="center">
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label ">Nome</span>
								<v-text-field class="pt-2" outlined v-model="form.nome" :rules="[rules.required]" :error-messages=[]
															ref="nome" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Cognome</span>
								<v-text-field class="pt-2" outlined v-model="form.cognome" :rules="[rules.required]" :error-messages=[]
															ref="cognome" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Data di Nascita</span>
								<v-menu
									ref="dateMenu"
									v-model="dateMenu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="auto"
									:rules="[rules.required]"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="form.data_di_nascita" outlined class="pt-2" dense :error-messages=[] ref="data_di_nascita"
																	:rules="[rules.required]" hide-details="auto" readonly v-bind="attrs" v-on="on"/>
									</template>
									<v-date-picker v-model="dateValue" no-title scrollable>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.dateMenu.save(form.data_di_nascita)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" lg="8" class="text-left pa-3 pb-0">
								<span class="input-label">Codice Fiscale</span>
								<v-text-field class="pt-2" outlined v-model="form.codice_fiscale" :rules="[rules.required]" :error-messages=[]
															ref="codice_fiscale" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Sesso</span>
								<v-select :items="['M','F']" class="pt-2" outlined v-model="form.sesso" :rules="[rules.required]"
													:error-messages=[]
													ref="sesso" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Nazione di residenza</span>
								<v-text-field class="pt-2" outlined v-model="form.nazione_di_residenza" disabled :rules="[rules.required]" :error-messages=[]
															ref="nazione_di_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Provincia di residenza</span>
								<v-select class="pt-2" outlined v-model="form.provincia_di_residenza" :items="province" item-text="nome" item-value="id"
													:rules="[rules.required]" :error-messages=[] ref="provincia_di_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Comune di residenza</span>
								<v-select :items="comuni_residenza" class="pt-2" outlined item-text="nome" item-value="id"
													v-model="form.comune_di_residenza" :disabled="!form.provincia_di_residenza" :rules="[rules.required]" :error-messages=[]
													ref="comune_di_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="3" class="text-left pa-3 pb-0">
								<span class="input-label">Toponimo residenza</span>
								<v-select :items="places" class="pt-2" outlined v-model="form.toponimo_residenza" :rules="[rules.required]"
													:error-messages=[]
													ref="toponimo_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="6" class="text-left pa-3 pb-0">
								<span class="input-label">Indirizzo di residenza</span>
								<v-text-field class="pt-2" outlined v-model="form.indirizzo_residenza" :rules="[rules.required]" :error-messages=[]
															ref="indirizzo_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="3" class="text-left pa-3 pb-0">
								<span class="input-label">Civico residenza</span>
								<v-text-field class="pt-2" outlined v-model="form.civico_residenza" :rules="[rules.required]"
															:error-messages=[] ref="civico_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">CAP residenza</span>
								<v-text-field class="pt-2" outlined v-model="form.cap_residenza" :rules="[rules.required]" :error-messages=[]
															ref="cap_residenza" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Provincia di nascita</span>
								<v-select class="pt-2" outlined v-model="form.provincia_di_nascita" :items="province" item-text="nome" item-value="id"
													:rules="[rules.required]" :error-messages=[] ref="provincia_di_nascita" dense
													hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Comune di nascita</span>
								<v-select :items="comuni_nascita" class="pt-2" outlined item-text="nome" item-value="id"
													v-model="form.comune_di_nascita" :disabled="!form.provincia_di_nascita" :rules="[rules.required]"
													:error-messages=[] ref="comune_di_nascita" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Cellulare</span>
								<v-text-field class="pt-2" outlined v-model="form.cellulare" :rules="[rules.required]" :error-messages=[]
															ref="cellulare" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="8" class="text-left pa-3 pb-0">
								<span class="input-label">E-mail</span>
								<v-text-field class="pt-2" outlined v-model="form.email" :rules="[rules.required,rules.email]"
															:error-messages=[]
															ref="email" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Provincia iscrizione albo</span>
								<v-select class="pt-2" outlined v-model="form.provincia_iscrizione_albo" :items="province" item-text="nome" item-value="id"
													:rules="[rules.required]" :error-messages=[] ref="provincia_iscrizione_albo" dense
													hide-details="auto"/>
							</v-col>
							<v-col cols="12" lg="4" class="text-left pa-3 pb-0">
								<span class="input-label">Iscrizione albo (numero tra 4 e 6 cifre)</span>
								<v-text-field class="pt-2" outlined v-model="form.numero_iscrizione_albo" :rules="[rules.required]" :error-messages=[]
															ref="numero_iscrizione_albo" dense hide-details="auto"/>
							</v-col>
							<v-col cols="12" class="pa-3 pb-0">
								<v-checkbox
									:ripple="false"
									v-model="form.checkbox1"
									ref="checkbox1"
									color="#4CAA8B"
									class="option-label"
									:rules="[rules.required]"
									hide-details="auto"
									label="Dichiaro di essere iscritto all’Albo dei Medici Chirurgi e degli Odontoiatri"
								></v-checkbox>
								<v-checkbox
									:ripple="false"
									v-model="form.checkbox2"
									ref="checkbox2"
									color="#4CAA8B"
									class="option-label"
									:rules="[rules.required]"
									hide-details="auto"
									label="Dichiaro di aver scaricato correttamente, visionato e accettato la documentazione pre-contrattuale IVASS"
								></v-checkbox>
								<p><a href="/static/attachments/allegato_3_4.pdf" target="_blank">Regolamento IVASS</a></p>
								<p><a href="/static/attachments/allegato_4ter.pdf" target="_blank">Regole di comportamento del distributore</a></p>
							</v-col>
							<v-col cols="12" class="pa-3 pt-8 text-left">
								<v-row>
									<v-col>
										Scaricare, compilare e ricaricate il questionario di adeguatezza<br>
										<a href="/static/attachments/questionario_adeguatezza_colpagrave.pdf" target="_blank" download="">
											<v-icon large color="primary">mdi-file-pdf-box</v-icon>
										</a>
									</v-col>
									<v-col>
										<v-file-input ref="questionario_adeguatezza" :rules="[rules.required]" dense outlined v-model="form.allegato_1"/>
									</v-col>
								</v-row>

							</v-col>
							<v-col cols="12" class="pa-3 pt-8 text-left">
								<v-row>
									<v-col>
										Scaricare, compilare e ricaricate il questionario di raccolta dati<br>
										<a href="/static/attachments/questionario_raccolta_dati_colpagrave.pdf" target="_blank" download="">
											<v-icon large color="primary">mdi-file-pdf-box</v-icon>
										</a>
									</v-col>
									<v-col>
										<v-file-input ref="questionario_raccolta_dati" :rules="[rules.required]" dense outlined v-model="form.allegato_2"/>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" class="pa-3 pt-8 text-left" v-if="!paypal">
								<v-btn dark depressed color="#4CAA8B" class="pa-4 pay-btn" @click="payClicked">
									PAGA CON CARTA
								</v-btn>
							</v-col>
							<v-col cols="12" class="pa-3 text-left section-sub-title" v-if="!paypal">
								* Il pagamento con carta è effettuato senza commissioni
							</v-col>
							<v-col cols="6" class="pt-10" v-if="richiesta?.id">
								<Paypal :id="richiesta?.id" :amount="polizza.premio"></Paypal>
							</v-col>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import Paypal from "@/components/Paypal";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
	name: "HomeForm",
	components: {Paypal},
	data() {
		return {
			rules: {
				required: (value) => !!value || "Campo obbligatorio",
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "e-mail non valido";
				},
			},
			form: {
				nome: '',
				cognome: '',
				data_di_nascita: '',
				provincia_di_nascita: '',
				comune_di_nascita: '',
				codice_fiscale: '',
				sesso: '',

				nazione_di_residenza: 'Italia',
				provincia_di_residenza: '',

				comune_di_residenza: '',
				toponimo_residenza: '',
				indirizzo_residenza: '',
				civico_residenza: '',
				cap_residenza: '',

				cellulare: '',
				email: '',

				provincia_iscrizione_albo: '',
				numero_iscrizione_albo: '',
				checkbox1: false,
				checkbox2: false,
				allegato_1: '',
				allegato_2: ''
			},
			dateValue: '',
			dateMenu: false,
			paypal: false,
			doc: null,
			province: [],
			comuni_residenza: [],
			comuni_nascita: [],
			places: ["Corso", "Piazza", "Strada", "Via", "Viale", "Altro"],
			categories: []
		}
	},
	watch: {
		dateValue: {
			handler(val) {
				if (val) {
					this.form.data_di_nascita = val
				} else this.form.data_di_nascita = ''
			}
		},
		'form.provincia_di_residenza': {
			handler(val) {
				if (val) {
					this.form.comune_di_residenza = '';
					this.fetchComuniResidenza(val);
				}
			}
		},
		'form.provincia_di_nascita': {
			handler(val) {
				if (val) {
					this.form.comune_di_nascita = '';
					this.fetchComuniNascita(val);
				}
			}
		}
	},
	computed: {
		...mapGetters({
			tipologiaPolizza: 'getTipologiaPolizza',
			categorieProfessionali: 'getCategorieProfessionali',
			categoriaProfessionale: 'getCategoriaProfessionale',
			categoria: 'getCategoria',
			categorieRischio: 'getCategorieRischio',
			categoriaRischio: 'getCategoriaRischio',
			polizze: 'getPolizze',
			polizza: 'getPolizza',
			richiesta: 'getRichiesta'
		})
	},
	created: async function () {
		await axios.get('province/').then((res) => {
			this.province = res.data
		})
	},
	methods: {
		selectCategoria(categoria){
			this.$store.dispatch('setCategoriaProfessionale', categoria)
			let div = document.getElementById('categorie_rischio');
			div.scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		selectCategoriaRischio(categoria){
			this.$store.dispatch('setCategoriaRischio', categoria)
			let div = document.getElementById('elenco_polizze');
			div.scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		selectPolizza(polizza){
			this.$store.dispatch('setPolizza', polizza)
			let div = document.getElementById('form_polizza');
			div.scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		async payClicked() {
			if (this.$refs.form.validate()) {
				await this.$store.dispatch('createRichiesta', this.form).then(()=>{
					this.paypal = !this.paypal
				})
			}
		},
		async fetchComuniResidenza(provincia) {
			let res = await axios.get(`province/${provincia}/comuni/`)
			this.comuni_residenza = res.data;
		},
		async fetchComuniNascita(provincia) {
			let res = await axios.get(`province/${provincia}/comuni/`)
			this.comuni_nascita = res.data;
		},
	}
}
</script>

<style>
a {
	font-weight: 600;
	color: rgb(76, 170, 139) !important;
	text-decoration: none;
}
fieldset, input {
	border-color: #204177 !important;
}
.pay-btn {
	height: inherit !important;
}
.input-label {
	color: #204177;
}
.active {
	border-top: 4px solid #F5CF58 !important;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}
.card {
	border-top: 4px solid transparent;
	background: white;
	box-shadow: 0 3px 17px #0000001C;
}
.selected {
	font-weight: 600;
	color: #204177;
	border-radius: 8px;
}
.done {
	background: rgb(76, 170, 139) !important;
	border-radius: 16px;
	color: white;
}
</style>
