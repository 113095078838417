import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import "@/assets/styles/main.css"
import axios from "axios";

Vue.prototype.moment = moment;

const serverUrl = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = `${serverUrl}/api/v1/`;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
