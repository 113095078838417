<template>
	<paypal-buttons :on-approve="onApprove" :create-order="createOrder" />
</template>

<script>

import Vue from "vue";
import router from "../router";

export default {
	name: "PayPalButton",
	props: [
		'amount',
		'paid',
		'id'
	],
	components: {
		'paypal-buttons': window.paypal.Buttons.driver('vue', Vue)
	},
	computed: {
		createOrder: function () {
			let t = this
			return (data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							amount: {
								value: t.amount,
							},
							description: "This is item description",
						},
					],
				});
			}
		},
		onApprove: function () {
			return (data, actions) => {
				actions.order.capture().then((res) => {
					router.push({ name: 'result', params: { id: res.id } });
					window.scrollTo(0, 0);
				});
			}
		},
		onError: function () {
			return (err) => {
				console.error(err);
				window.location.href = "/your-error-page-here";
			}
		},
	},
}
</script>
