<template>
	<div>
		<transition name="fade">
			<HomeChoice v-if="!tipologiaPolizza || !tipologiaPolizza.has_polizza"/>
			<HomeForm v-else/>
		</transition>
	</div>
</template>

<script>
import HomeChoice from "@/views/HomeChoice";
import HomeForm from "@/views/HomeForm"
import { mapGetters } from 'vuex'

export default {
	components: {
		HomeChoice,
		HomeForm
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			tipologiaPolizza: 'getTipologiaPolizza'
		}),
		result() {
			return this.$store.state.result
		},
	},
};
</script>
