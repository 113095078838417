/*
| ===================================================
| This File is for policy related cards
| ===================================================
*/
<template>
	<v-row no-gutters>
		<v-col cols="12">
			<v-row no-gutters justify="center">
				<v-col cols="10" class="box mt-10 pa-8" v-for="categoria in categorie" v-bind:key="categoria.id" :class="{'featured': categoria.in_evidenza}">
					<v-row>
						<v-col cols="12" md="5">
							<v-row no-gutters>
								<v-col cols="12" class="section-title mb-5">
									{{categoria.nome}}
								</v-col>
								<v-col cols="12" class="section-sub-title">
									{{categoria.descrizione}}
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="7">
							<v-row no-gutters class="fill-height" align-content="center">
								<v-col cols="12" class="sub-box mb-5 pa-5" v-for="tipologia in categoria.tipologie" v-bind:key="tipologia.id">
									<v-row no-gutters justify="center" justify-md="start">
										<v-col class="section-title pb-2 pb-md-0" align-self="center">
											<v-row no-gutters align-content="center" class="text-center">
												<span>{{tipologia.nome}}</span>
											</v-row>
										</v-col>
										<v-btn
											depressed
											:color="categoria.in_evidenza ? '#204177': '#EFFAF6'"
											@click="select(tipologia)">
											<span style="letter-spacing: 0.05rem" :class="{'featured': categoria.in_evidenza}">
												SELEZIONA POLIZZA
											</span>
											<v-icon :color="categoria.in_evidenza ? '#FFFFFF': '#4CAA8B'">mdi-chevron-right</v-icon>
										</v-btn>
									</v-row>
								</v-col >
							</v-row>
						</v-col>
					</v-row>
					<v-row justify="center" class="pt-5 pb-1" v-if="tipologiaPolizza && !tipologiaPolizza.has_polizza && tipologiaPolizza.categoria == categoria.id">
						<v-col cols="10">
							<v-row class="box-nested no-gutters py-5">
								<v-col cols="12" class="section-title pb-2 text-center">
									<span>{{tipologiaPolizza.nome}}</span>
								</v-col>
								<v-col cols="12" class="section-sub-title text-center">
							<span>
								Contattare l'agenzia:<br>
								Telefono: 377/3595366 | Email: lmelanitto@mfbunderwritingagency.it <br>
								Sede: Corso di Porta Nuova n. 16 - 20121 Milano
							</span>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: "Policy",
	data() {
		return {
			policies: [
				{id: 1, name: 'RC DIPENDENTI RC COLPA GRAVE'},
				{id: 2, name: 'RC DIPENDENTI TUTELA LEGALE'},
				{id: 3, name: 'RC CLINICHE E ISTITUTI DI CURA'},
				{id: 4, name: 'RC LABORATORIO'},
				{id: 5, name: 'RC MEDICI PROFESSIONISTI'},
			]
		}
	},
	computed: {
		...mapGetters({
			tree: 'getTree',
			categorie: 'getCategorie',
			tipologiaPolizza: 'getTipologiaPolizza'
		})
	},
	methods: {
		select(tipologiaPolizza) {
			this.$store.dispatch('setTipologiaPolizza', tipologiaPolizza)
			/*
			if(value.id === 1) {
				window.scrollTo(0, 0);
			}
			*/
		}
	}
}
</script>

<style scoped>
.box {
	border-radius: 32px;
	background: #fdf6de;
}

.box.featured {
	background: #e7f0ff;
}

.sub-box {
	border-radius: 25px;
	background: white;
	box-shadow: 0px 3px 17px #0000001C;
}
.box-nested {
	border-radius: 25px;
	background: #F5CF58;
}

.v-btn__content span {
	color: #4CAA8B;
}

.v-btn__content span.featured{
	color: #ffffff;
}
</style>
