<template>
	<v-row class="footer pt-10 pb-10 mt-10">
		<v-col cols="12">
			<v-row justify="center">
				<v-col class="text-center text-lg-left" cols="10">
					<span class="footer-title">Contatti</span>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-row>
			<v-col class="text-center text-lg-left" cols="12" lg="4" offset-lg="1">
				<p class="footer-sub-title">E-MAIL</p>
				<p class="footer-content"><a href="mailto:lmelanitto@mfbunderwritingagency.it">lmelanitto@mfbunderwritingagency.it</a></p>
				<p class="footer-sub-title">PEC</p>
				<p class="footer-content"><a href="mailto:mfbunderwritingagency@pec-legal.it">mfbunderwritingagency@pec-legal.it</a></p>
			</v-col>
			<v-col class="text-center text-lg-left" cols="12" lg="3">
				<p class="footer-sub-title">RAGIONE SOCIALE</p>
				<p class="footer-content">MFB Underwriting Agency S.r.l</p>
				<p class="footer-sub-title" >TELEFONO</p>
				<p class="footer-content" style="margin-bottom:0;">+393457353364</p>
				<small class="footer-info" style="font-size: 11px; !important">
					Il numero sarà attivo dal lunedì al venerdì nelle seguenti fasce orarie:
					9-13 15-18
				</small>
			</v-col>
			<v-col class="text-center text-lg-left" cols="12" lg="4">
				<p class="footer-sub-title">INDIRIZZO</p>
				<p class="footer-content">Corso di Porta Nuova n. 16 - CAP 20121 Milano</p>
			</v-col>
			<v-col class="text-center" cols="12">
				<p class="footer-sub-title">NUMERO ISCRIZIONE IVASS</p>
				<p class="footer-content" style="margin-bottom:0;">Società sottoposta alla vigilanza IVASS</p>
				<p class="footer-content">Registro RUI Sezione A numero iscrizione A000652351 - <a href="https://ruipubblico.ivass.it/rui-pubblica/ng/#/workspace/registro-unico-intermediari">Consulta il registro</a></p>
				<p class="footer-content">Numero REA MI2584361</p>

				<hr style="max-width: 800px;  margin-right: auto; color: white; margin: 30px auto">
				<p class="footer-content" style="max-width: 800px; margin-left: auto; margin-right: auto; font-size: 12px;">
								Informazioni per l’assicurato/contraente.<br>
Qualora sussista una doglianza attinente al comportamento dell’intermediario, vi è la facoltà di inoltrare reclamo per iscritto ad MFB Underwriting Agency S.r.l, Ufficio Reclami Via Corso di Porta Nuova n. 16, Milano oppure a mezzo e-mail, specificando nell'oggetto "RECLAMO", all'indirizzo: <a href="mailto:mfbunderwritingagency@pec-legal.it">mfbunderwritingagency@pec-legal.it</a>.<br>
La normativa concede 45 giorni entro i quali fornire una risposta
				</p>

				<hr style="max-width: 800px;  margin-right: auto; color: white; margin: 30px auto">
			</v-col>
		</v-row>
		</v-col>
		<v-col cols="12">
			<v-row class="text-center">
			<v-col>
				<p class="footer-info">
					<a href="/static/attachments/MFB_MODULO_DENUNCIA_SINISTRO.pdf" target="_blank">MODULO DENUNCIA SINISTRO</a>
				</p>
				<p class="footer-info">
					<a href="/static/attachments/MFB_SET_INFORMATIVO_COLPA_GRAVE.pdf" target="_blank">SET INFORMATIVO</a>
				</p>
				<p class="footer-info mb-0">
					<a href="/static/attachments/MFB_privacy.pdf" target="_blank">PRIVACY</a> - GESTIONE RECLAMI
				</p>
			</v-col>
		</v-row>
		</v-col>
	</v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters({
			getShowCatalogue: 'getShowCatalogue'
		}),
		showCatalogue() {
			return this.getShowCatalogue
		}
	}
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.footer {
	width: 100%;
	margin: 0;
	background: #204177 !important;
}

.footer-title {
	font-family: "Raleway", sans-serif;
	font-weight: bold;
	font-size: 1.75rem;
	letter-spacing: 0.53px;
	color: white;
	opacity: 1;
}

.footer-sub-title {
	font-family: "Open Sans", sans-serif;
	font-weight: normal;
	font-size: 0.9rem;
	letter-spacing: 2px;
	color: white;
	opacity: 0.5;
}

.footer-content {
	font-family: "Open Sans", sans-serif;
	font-weight: normal;
	font-size: 16px;
	letter-spacing: 0;
	color: white;
	opacity: 1;
}

.footer-info {
	font-family: "Open Sans", sans-serif;
	font-weight: normal;
	font-size: 0.9rem;
	letter-spacing: 0;
	color: white;
	opacity: 1;
}
</style>
