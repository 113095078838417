import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		categorie: [],
		categoriaProfessionale: null,
		tipologiaPolizza: null,
		categoriaRischio: null,
		polizza: null,
		richiesta: null,
	},
	mutations: {
		resetAll(state) {
			state.categoriaProfessionale = null;
			state.tipologiaPolizza = null;
			state.categoriaRischio = null;
			state.polizza = null;
			state.richiesta = null;
		},
		setTipologiaPolizza(state, payload) {
			state.tipologiaPolizza = payload;
		},
		setCategorie(state, payload) {
			state.categorie = payload
		},
		setCategoriaProfessionale(state, payload) {
			state.categoriaProfessionale = payload
		},
		setCategoriaRischio(state, payload) {
			state.categoriaRischio = payload
		},
		setPolizza(state, payload) {
			state.polizza = payload
		},
		setRichiesta(state, payload) {
			state.richiesta = payload
		}
	},
	actions: {
		resetAll({commit}) {
			commit('resetAll')
		},
		setTipologiaPolizza({commit}, payload) {
			commit('setTipologiaPolizza', payload)
		},
		setCategoriaProfessionale({commit}, payload) {
			commit('setCategoriaProfessionale', payload)
		},
		setCategoriaRischio({commit}, payload) {
			commit('setCategoriaRischio', payload)
		},
		setPolizza({commit}, payload) {
			commit('setPolizza', payload)
		},
		async fetchCategorie({commit}) {
			await axios.get('categorie-polizza/').then((res) => {
				commit('setCategorie', res.data);
			})
		},
		async createRichiesta({commit, state}, payload) {
			const formData = new FormData();
			let obj = {
				polizza: state.polizza.id, ...payload
			}
			Object.keys(obj).forEach(key => {
				formData.append(key, obj[key]);
			});

			await axios.post('richieste/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((res) => {
				commit('setRichiesta', res.data);
			})
		},
		async updateRichiesta({commit, state}, payload) {
			const formData = new FormData();

			Object.keys(payload).forEach(key => {
				formData.append(key, payload[key]);
			});

			await axios.patch('richieste/' + state.richiesta.id + '/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((res) => {
				commit('setRichiesta', res.data);
			})
		}
	},
	getters: {
		getTree: (state) => state.result.tree,
		getCategorie: (state) => state.categorie,
		getTipologiaPolizza: (state) => state.tipologiaPolizza,
		getCategoria: (state) => {
			return state.categorie.find(categoria => categoria.id === state.tipologiaPolizza.categoria);
		},
		getCategoriaProfessionale: (state) => state.categoriaProfessionale,
		getCategorieProfessionali: (state) => {
			const categorie = []; // Array per tenere traccia delle categorie uniche

			if (state.tipologiaPolizza) {
				state.tipologiaPolizza.polizze.forEach(polizza => {
					const categoriaProfessionale = {
						id: polizza.condizione_professionale.id,
						nome: polizza.condizione_professionale.nome
					};

					const isCategoryAdded = categorie.some(category => category.id === categoriaProfessionale.id);

					if (!isCategoryAdded) {
						categorie.push(categoriaProfessionale);
					}
				});
			}
			return categorie;
		},
		getCategorieRischio: (state) => {
			const categorie = []; // Array per tenere traccia delle categorie uniche

			if (state.tipologiaPolizza && state.categoriaProfessionale) {
				state.tipologiaPolizza.polizze.filter(polizza => {
					return polizza.condizione_professionale.id === state.categoriaProfessionale.id;
				}).forEach(polizza => {
					const categoriaRischio = {
						id: polizza.categoria_di_rischio.id,
						nome: polizza.categoria_di_rischio.nome
					};

					const isCategoryAdded = categorie.some(category => category.id === categoriaRischio.id);

					if (!isCategoryAdded) {
						categorie.push(categoriaRischio);
					}
				});
			}
			return categorie;
		},
		getCategoriaRischio: (state) => state.categoriaRischio,
		getPolizze: (state) => {
			if (state.tipologiaPolizza && state.categoriaProfessionale && state.categoriaRischio) {
				return state.tipologiaPolizza.polizze.filter(polizza => {
					return polizza.condizione_professionale.id === state.categoriaProfessionale.id && polizza.categoria_di_rischio.id === state.categoriaRischio.id;
				});
			} else {
				return []
			}
		},
		getPolizza: (state) => state.polizza,
		getRichiesta: (state) => state.richiesta
	}
});
